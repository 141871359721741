<template>
  <v-container
    fluid
    tag="section"
  >
    <v-dialog
      v-model="dayStatusShowDialog"
      max-width="800"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click=" dayStatusShowDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Day - Item Statues (Open, Done)</v-toolbar-title>
        </v-toolbar>
        <v-data-table
          :headers="dayReceiptItemsHeader"
          :items="dayReceiptItemsData"
          sort-by="id"
          class="elevation-1"
          dense
          :search="searchDayReceiptItem"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Items List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-row class="mt-2">
                <v-spacer></v-spacer>
                <v-col
                  cols="6"
                  md="4"
                >
                  <v-select
                    v-model="showFilterDayStatus"
                    :items="showFilterDayStatusObject"
                    item-text="text"
                    item-value="value"
                    label="Select Status You Want Display"
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    v-model="searchDayReceiptItem"
                    label="Search Item Keyword, or Date"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <snack-bar ref="snackBar" />
    <v-card>
      <v-card-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-card-title>
      <v-sheet
        tile
        height="54"
        class="d-flex"
      >
        <v-btn
          icon
          class="ma-2"
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-select
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          class="ma-2"
          label="type"
        />
        <v-select
          v-model="weekday"
          :items="weekdays"
          dense
          outlined
          hide-details
          label="weekdays"
          class="ma-2"
        />
        <v-btn
          icon
          class="ma-2"
          @click="next"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
      <v-sheet height="800">
        <v-calendar
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:day="showDayStatus"
          @change="checkDateRange"
        />
      </v-sheet>
      <div class="py-3" />
    </v-card>
  </v-container>
</template>

<script>
  import statisticsApi from '../api/statistics'
  import SnackBar from '../components/app/SnackBar'
  import ReceiptItemDataHandle from '@/util/receipt-item-data-handle'
  import store from '@/store'
  export default {
    name: 'Calendar',
    components: { SnackBar },
    data () {
      return {
        searchDayReceiptItem: '',
        showFilterDayStatus: 0,
        showFilterDayStatusObject: [
          { text: 'Show All', value: 0 },
          { text: 'Show Open', value: 1 },
          { text: 'Show Done', value: 2 },
        ],
        dayStatusShowDialog: false,
        dayReceiptItemsHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'PN', value: 'item_part_number', sortable: false },
          { text: 'BIN #', value: 'item.bin_number', sortable: false },
          { text: 'PO/Receipt #', value: 'item_receipt.po_number', sortable: true },
          {
            text: 'Status',
            value: 'task_status.name',
            sortable: true,
            filter: value => {
              if (this.showFilterDayStatus === 0) return true
              if (this.showFilterDayStatus === 1) return value !== 'Done' && value !== 'Done with Issue'
              if (this.showFilterDayStatus === 2) return value !== 'Created' && value !== 'Processing'
            },
          },
          { text: 'Qty', value: 'label_show_quantity', sortable: true },
          { text: 'RDD (Days)', value: 'remain_times', sortable: true },
          { text: 'EDD (Days)', value: 'estimate_due_date', sortable: true },
        ],
        dayReceiptItemsData: [],
        dayReceiptItemsDefaultData: [],
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
          { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
          { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        events: [],
        dateFrom: '',
        dateTo: '',
      }
    },
    watch: {

    },
    methods: {
      loadDefaultCalendar (from, to) {
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Date is on the way',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
        statisticsApi.calendarIndex(from, to).then(response => {
          if (this.events.length > 0) this.events = []
          const data = response.data.data
          this.events.push({
            name: 'Delay Items ' + data.delayTestItem[0].totalDelayItems,
            start: new Date(),
            end: new Date(),
            color: 'red',
          })
          data.estimateItem.forEach((item, index) => {
            this.events.push({
              name: 'Items Estimate Due ' + item.requestDueItems,
              start: item.estimateDueDate,
              end: item.estimateDueDate,
            })
          })
          data.createReport.forEach((item, index) => {
            this.events.push({
              name: 'Items Create ' + item.totalCreated,
              start: item.receiptDate,
              end: item.receiptDate,
              color: 'green',
            })
          })
          data.doneReport.forEach((item, index) => {
            this.events.push({
              name: 'Items Pass ' + item.totalTested + ' Failed ' + item.totalFailedInTesting,
              start: item.createdAt,
              end: item.createdAt,
              color: 'info',
            })
          })
        }).catch(error => {
          console.log(error)
        })
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      checkDateRange (event) {
        if (this.dateFrom > event.start.date || this.dateTo < event.end.date) {
          this.dateFrom = event.start.date
          this.dateTo = event.end.date
          this.loadDefaultCalendar(event.start.date, event.end.date)
        }
      },
      showDayStatus (event) {
        store.set('message/storeMessage', {
          color: 'info',
          text: 'Status data is on the way. Data loading will take some time',
          timeout: 3000,
        })
        this.$refs.snackBar.activeStatusInfo()
        statisticsApi.calendarDayStatus(event.date).then(response => {
          this.dayReceiptItemsData = ReceiptItemDataHandle.dataFormatArray(response.data.data)
          this.dayStatusShowDialog = true
        }).catch(error => {
          console.log(error)
        })
      },
      filterOnlyCapsText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },
    },
  }
</script>

<style scoped>

</style>
