import moment from 'moment'
export default {
  dateFormatSingle (data) {
    data.updated_at = moment.utc(data.updated_at).local().format('YYYY-MMM-DD HH:ss')
    data.estimate_due_date = moment.utc(data.estimate_due_date).local().fromNow()
    data.item_test.forEach((test, index) => {
      test.created_at = moment.utc(data.updated_at).local().format('YYYY-MMM-DD HH:ss')
      test.pass_in_testing = test.tested_quantity - test.failed_in_testing
    })
    data.item_receipt_comment.forEach((comment, index) => {
      comment.created_at = moment.utc(data.updated_at).local().format('YYYY-MMM-DD HH:ss')
    })
    data.comment.forEach((comment, index) => {
      comment.created_at = moment.utc(data.updated_at).local().format('YYYY-MMM-DD HH:ss')
    })
    return data
  },
  dataFormatArray (data) {
    data.forEach((element) => {
      if (element.request_due_date == null || element.request_due_date === 'null') {
        element.remain_times = 'N/A'
        element.request_due_date = null
      } else {
        element.remain_times = moment.utc(element.request_due_date).local().fromNow()
        element.request_due_date = moment.utc(element.request_due_date).local().format('YYYY-MMM-DD HH:ss')
      }
      element.estimate_due_date = moment.utc(element.estimate_due_date).local().format('YYYY-MMM-DD HH:ss')
      element.created_at = moment.utc(element.created_at).local().format('YYYY-MMM-DD HH:ss')
      element.updated_at = moment.utc(element.updated_at).local().format('YYYY-MMM-DD HH:ss')
    })
    return data
  },
}
