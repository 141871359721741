import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  calendarIndex (from, to) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'calendar',
      data: {
        from: from,
        to: to,
      },
    })
  },
  calendarDayStatus (date) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'ItemReceiptItem/itemsStatusDateReport',
      params: {
        date: date,
      },
    })
  },
}
